import React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import HeaderUser from './HeaderUser';
import Footer from './Footer';
import image from '../images/iStock-1442447917-2.jpg';
import config from '../config';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/aboutus.webflow.css';



function AboutUs() {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
    };

    const token = localStorage.getItem('token');


    return (
        <div>
            {token ? <HeaderUser /> : <Header />}
            <div>
                <div className="section cc-home-wrap">
                    <div className="intro-header cc-subpage">
                        <div className="intro-content">
                            <div className="heading-jumbo-2">O&nbsp;NAMA<br /></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="motto-wrap">
                            <div className="heading-jumbo-small">Dobrodošli na našu platformu za osiguranje – vaše centralno mjesto za sve vaše potrebe osiguranja. Mi smo inovativni servis koji povezuje klijente sa više osiguravajućih kuća, omogućavajući vam da jednostavno i brzo dobijete najbolje ponude za sve vrste osiguranja.</div>
                        </div>
                        <div className="divider" />
                        <div className="about-story-wrap">
                            <p className="paragraph-light">Naša misija je da proces osiguranja učinimo što jednostavnijim i transparentnijim. Verujemo da svaki klijent zaslužuje priliku da izabere najbolju moguću ponudu prilagođenu svojim potrebama i budžetu. Kroz našu platformu, možete podnijeti zahtjev za osiguranje i u kratkom roku dobiti ponude od različitih osiguravajućih kuća.<br /><br />Mi vjerujemo da osiguranje ne treba da bude komplikovano. Naš cilj je da vam pružimo jednostavan, brz i transparentan način da dobijete osiguranje koje vam je potrebno. Pridružite nam se i otkrijte novi, bolji način osiguranja.</p>
                        </div>
                        <div className="divider" />
                    </div>
                </div>
                <div className="section">
                    <div className="container">
                        <div className="section-heading-wrap">
                            <div className="label cc-light">Ono u čemu smo najbolji</div>
                            <h2>NAŠE USLUGE</h2>
                        </div>
                        <div className="w-layout-grid our-services-grid">
                            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118e6-737b4948"><img src="../images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp" width={50} alt="" sizes="(max-width: 479px) 25vw, 50px" srcSet="images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1.webp 500w, images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp 540w" className="service-icon" />
                                <div className="paragraph-bigger">Jednostavno</div>
                                <div className="paragraph-light">Popunite jedan zahtjev i dobijte više ponuda bez potrebe da kontaktirate svaku osiguravajuću kuću pojedinačno. Naša platforma vam štedi vreme i energiju. </div>
                            </div>
                            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118ec-737b4948"><img src="../images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp" width={50} alt="" sizes="(max-width: 479px) 25vw, 50px" srcSet="images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1.webp 500w, images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp 540w" className="service-icon" />
                                <div className="paragraph-bigger">Brzina</div>
                                <div className="paragraph-light">U toku smo s vremenom - u učestaloj digitalizaciji, uočili smo priliku da proces osiguranja učinimo bržim i jednotavnijim.<br /></div>
                            </div>
                            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118f2-737b4948"><img src="../images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp" width={50} alt="" sizes="(max-width: 479px) 25vw, 50px" srcSet="images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1.webp 500w, images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp 540w" className="service-icon" />
                                <div className="paragraph-bigger">Transparentnost</div>
                                <div className="paragraph-light">Sve ponude koje dobijete prikazane su jasno i detaljno, omogućavajući vam da lako uporedite uslove, cijene i pokrića.<br /></div>
                            </div>
                            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118f8-737b4948"><img src="../images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp" width={50} alt="" sizes="(max-width: 479px) 25vw, 50px" srcSet="images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1.webp 500w, images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp 540w" className="service-icon" />
                                <div className="paragraph-bigger">Zadovoljni Klijenti</div>
                                <div className="paragraph-light">Iskustva i recenzije naših klijenata uvjerili su nas da je naša ideja i platforma na pravom putu. Naš tim neprekidno usavršava platformu i osluškuje potrebe klijenata. Predanost našeg tima je konstanta.</div>
                            </div>
                            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b118fe-737b4948"><img src="../images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp" width={50} alt="" sizes="(max-width: 479px) 25vw, 50px" srcSet="images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1.webp 500w, images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp 540w" className="service-icon" />
                                <div className="paragraph-bigger">Podrška<br /></div>
                                <div className="paragraph-light">Naš tim stručnjaka za osiguranje je tu da vam pomogne tokom cijelog procesa. Ako imate bilo kakva pitanja ili nedoumice, slobodno nas kontaktirajte – tu smo da vam pružimo podršku i savjete.</div>
                            </div>
                            <div id="w-node-ca1212d8-4247-8b87-bc90-543393b11905-737b4948"><img src="../images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp" width={50} alt="" sizes="(max-width: 479px) 25vw, 50px" srcSet="images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1.webp 500w, images/360_F_736368432_zEwmLGtbp3U6snbE92ZWUZto5CZM4Kdc-1_1.webp 540w" className="service-icon" />
                                <div className="paragraph-bigger">Fleksibilnost<br /></div>
                                <div className="paragraph-light">Bez obzira da li tražite kasko osiguranje za vaš automobil ili osiguranje za vašu imovinu, naša platforma vam nudi širok izbor opcija prilagođenih vašim specifičnim potrebama.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section cc-cta">
                    <div className="container">
                        <div className="cta-wrap">
                            <div>
                                <div className="cta-text">
                                    <div className="heading-jumbo-small">Osiguraj sebe i svoju imovinu<br /></div>
                                    <div className="paragraph-bigger cc-bigger-light">Danas je dan da zaštitite svoju budućnost. Registracijom na našem sajtu, korak po korak, pravite sigurnije sutra. Ne čekajte više - započnite put prema mirnijem životu danas.<br /></div>
                                </div>
                                <a href="/registration" className="button cc-jumbo-button w-inline-block">
                                    <div>registruj se</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>


    );
}

export default AboutUs;
