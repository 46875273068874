import React from "react";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';

const FooterUser = () => {
    return(

        <section className="footer-subscribe">
            <div className="brix---section-position-relative">
            <div className="brix---container-default-4 w-container footer-container">
                <div className="brix---inner-container-700px-center">
                    <div data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb61" className="brix---text-center-4">
                        <div className="brix---subtitle-2">kontaktirajte nas</div>
                        <div className="brix---color-neutral-812">
                            <h1 className="brix---heading-h1-size-2">Stupite u kontakt odmah</h1>
                        </div>
                        <div className="brix---mg-bottom-40px-2">
                            <div className="brix---color-neutral-813">
                                <p className="brix---paragraph-default-4">Naš tim stručnjaka je tu da odgovori na sva vaša pitanja i pomogne vam u odabiru pravog osiguranja. Bilo da imate nedoumica ili želite više informacija, slobodno nas kontaktirajte putem telefona ili e-pošte. Tu smo da vam pružimo podršku u svakom trenutku!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brix---mg-bottom-160px">
                    <div data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb6c" className="footer-contact-container">

                        <a data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb6d" href="mailto:info@osiguraj-se.com?subject=Osiguraj-Se" className="brix---card-link-center w-inline-block contact-card">
                            <div className="brix---mg-bottom-24px-2">
                                <img src="../images/66c62c2a1892a981dd6fadd3_icon-brix-template-Email.jpg" alt="Email Icon - Elements Webflow Library - BRIX Templates" className="brix---square-icon" />
                            </div>
                            <div className="brix---color-neutral-812">
                                <h3 className="brix---heading-h3-size-3">Email:</h3>
                            </div>
                            <div className="brix---mg-bottom-24px-2">
                                <div className="brix---color-neutral-813">
                                    <p className="brix---paragraph-default-4">Kontaktirajte nas putem E-mail adrese</p>
                                </div>
                            </div>
                            <div data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb77" className="brix---link-wrapper-color-primary">
                                <div className="brix---text-200-bold-3">info@osiguraj-se.com<br /></div>
                            </div>
                        </a>


                        <a data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb7b" href="https://www.google.com/maps?q=Novice+Cerovića+8,+Banja+Luka" className="brix---card-link-center w-inline-block contact-card">
                            <div className="brix---mg-bottom-24px-2"><img src="../images/66c62c291892a981dd6fad6a_icon-brix-template-Building.jpg" alt="Building Icon - Elements Webflow Library - BRIX Templates" className="brix---square-icon" /></div>
                            <div className="brix---color-neutral-812">
                                <h3 className="brix---heading-h3-size-3">Adresa</h3>
                            </div>
                            <div className="brix---mg-bottom-24px-2">
                                <div className="brix---color-neutral-813">
                                    <p className="brix---paragraph-default-4">Naša kancelarija je dostupna za klijente svakim radnim danom 08-16 </p>
                                </div>
                            </div>
                            <div data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb85" className="brix---link-wrapper-color-primary">
                                <div className="brix---text-200-bold-3">Novice Cerovića 8, Banja Luka </div>
                            </div>
                        </a>

                        <a data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb88" href="tel:+387XXXXX" className="brix---card-link-center w-inline-block contact-card">
                            <div className="brix---mg-bottom-24px-2"><img src="../images/66c62c291892a981dd6fad52_icon-brix-template-Phone.jpg" alt="Phone - Elements Webflow Library - BRIX Templates" className="brix---square-icon" /></div>
                            <div className="brix---color-neutral-812">
                                <h3 className="brix---heading-h3-size-3">Kontakt broj</h3>
                            </div>
                            <div className="brix---mg-bottom-24px-2">
                                <div className="brix---color-neutral-813">
                                    <p className="brix---paragraph-default-4">Pozovite nas i direktno komunicirajte sa našim agentom</p>
                                </div>
                            </div>
                            <div data-w-id="5e0ea9d4-7825-a132-05ce-d87baa13eb92" className="brix---link-wrapper-color-primary">
                                <div className="brix---text-200-bold-3">+387 XXXXX</div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div className="brix---half-bg-bottom-70" />
        </div>
            <div className="container-3">
                <div className="footer-form-two w-form">
                    <form id="wf-form-Footer-Form-Two" name="wf-form-Footer-Form-Two" data-name="Footer Form Two" method="get" className="footer-form-container-two" data-wf-page-id="65479fae12815d63408e5274" data-wf-element-id="01e7ad09-f3c4-d2d6-d39f-a2e108364f26">
                        <div className="footer-form-title"><strong>Prijavite se na mejling listu</strong></div>
                        <div className="footer-form-block-two"><input type="email" className="footer-form-input w-input" maxLength={256} name="Footer-Email-Two" data-name="Footer Email Two" aria-label="Enter your email" placeholder="Unesite vaš email" id="Footer-Email-Two" required /><input type="submit" defaultValue="Prijavite se" data-wait="Please wait..." className="button-primary footer-form-button w-button" /></div>
                    </form>
                    <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div className="footer-wrapper-three">
                    <div className="footer-block-three">
                        <a href="/about-us" className="footer-link-three">O Nama</a>
                        <a href="/main-page/kasko" className="footer-link-three">Kasko</a>
                        <a href="/main-page/property" className="footer-link-three">Imovinsko</a>
                        <a href="/main-page/choose" className="footer-link-three">Pregled pristiglih ponuda</a>
                    </div>
                    <div className="footer-social-block-three">
                        <a href="https://www.facebook.com/profile.php?id=61562145387124" className="footer-social-link-three w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a705912aaeb_facebook%20big%20filled.svg" loading="lazy" alt="" /></a>
                        <a href="https://www.instagram.com/osiguraj.se/" className="footer-social-link-three w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a61f512aaed_instagram%20big.svg" loading="lazy" alt="" /></a>
                    </div>
                </div>
                <div className="footer-divider-two" />
                <div className="footer-bottom">
                    <div className="footer-copyright">© 2024 Osiguraj se. All rights reserved</div>
                    <div className="footer-legal-block">
                        <a href="/" className="footer-legal-link">Terms Of Use</a>
                        <a href="/" className="footer-legal-link">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterUser;
