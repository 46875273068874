import React from 'react';
import '../LandingPage.css';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import Header from "./Header";
import Footer from "./Footer";
import Propaganda from "./Propaganda";
import Accordion from './Accordion';

const LandingPage = () => {
            return (
                <div>
                    <Header/>
                    <div className="section cc-store-home-wrap intro-container">
                        <div className="intro-header">
                            <div className="intro-content cc-homepage">
                                <div data-w-id="86e64837-0616-515b-4568-76c147234d20" style={{
                                    WebkitTransform: 'translate3d(0px, -500px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                                    MozTransform: 'translate3d(0px, -500px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                                    msTransform: 'translate3d(0px, -500px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                                    transform: 'translate3d(0px, -500px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                                    opacity: 0
                                }} className="intro-text">
                                    <div className="heading-jumbo">OsigurajSe</div>
                                    <div className="paragraph-bigger cc-bigger-white-light">Vaša Vizija Budućnosti<br/>
                                    </div>
                                </div>
                            </div>
                            <a href="/about-us" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
                                <div>saznaj više</div>
                            </a>
                        </div>
                        <div className="container">
                            <div className="motto-wrap">
                                <div className="label cc-light" id="advantages">u šta mi vjerujemo</div>
                                <div className="heading-jumbo-small">Online sigurnost dodirom ekrana - brže, lakše, pametnije.<br /></div>
                            </div>
                            <div className="divider" />
                            <div className="home-content-wrap">
                                <div className="w-layout-grid about-grid">
                                    <div id="w-node-_86e64837-0616-515b-4568-76c147234d34-408e5274">
                                        <div className="home-section-wrap">
                                            <div className="label cc-light">o nama</div>
                                            <h2 className="section-heading">Ko smo mi</h2>
                                            <p className="paragraph-light">Svaki korak prema sigurnoj budućnosti sada je brži i jednostavniji uz nas! Mi smo pioniri u online osiguranju, prva platforma koja vam omogućava da ubrzate proces osiguranja i dobijete najbolje i najpovoljnije ponude na samo nekoliko klikova.</p>
                                        </div>
                                        <a href="/login" className="button w-inline-block">
                                            <div>Saznaj više</div>
                                        </a>
                                    </div><img className="image-2" src="../images/iStock-1476769282.jpg" data-w-id="86e64837-0616-515b-4568-76c147234d3f" id="w-node-_86e64837-0616-515b-4568-76c147234d3f-408e5274" />
                                </div>
                                <div className="w-layout-grid about-grid cc-about-2">
                                    <div id="w-node-_86e64837-0616-515b-4568-76c147234d41-408e5274">
                                        <div className="home-section-wrap">
                                            <div className="label cc-light">naš tim</div>
                                            <h2 className="section-heading">Kako ovo sve funkcioniše?</h2>
                                            <p className="paragraph-light">Jednostavno unesite svoje potrebe i informacije, a mi ćemo automatski uputiti upit prema više osiguravajućih kuća. U što kraćem roku ćete dobiti personalizovane ponude, što znači da možete brzo i lako usporediti različite opcije kako biste pronašli najbolju i najpovljinu opciju za vas.</p>
                                        </div>
                                        <a href="/about-us" className="button w-inline-block">
                                            <div>saznaj više</div>
                                        </a>
                                    </div><img className="image-3" src="../images/iStock-645011514 (1).jpg" data-w-id="86e64837-0616-515b-4568-76c147234d4c" id="w-node-_86e64837-0616-515b-4568-76c147234d4c-408e5274" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="container">
                            <section className="team-circles">
                                <div className="container-2">
                                    <h2 className="centered-heading"  id="process">Čitav proces predstavljen u nekoliko koraka</h2>
                                    <p className="centered-subheading">Naš razvojni tim se potrudio da interakcija sistema i korisnika bude jednostavna, brza i učinkovita.</p>
                                    <div className="team-grid">
                                        <div id="w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500bf-408e5274" className="team-card"><img className="team-member-image" src="../images/laptop-image-with-blue-borders-384545584.png" data-w-id="cfa202fe-2bd5-3e0b-25c9-7885e05500c0" loading="lazy" srcSet="../images/laptop-image-with-blue-borders-384545584-p-500.png 500w, images/laptop-image-with-blue-borders-384545584-p-800.png 800w, images/laptop-image-with-blue-borders-384545584.png 1024w" />
                                            <div className="team-member-name-2">1. Korak</div>
                                            <div className="team-member-position">Registracija / Prijava</div>
                                            <p>Uz nekoliko prostih klikova, unesite osnovne podatke i registrujte se na platformu ili pristupite već postojećem nalogu.</p>
                                        </div>
                                        <div id="w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500ca-408e5274" className="team-card"><img className="team-member-image" src="../images/humandHands.png" data-w-id="cfa202fe-2bd5-3e0b-25c9-7885e05500cb" loading="lazy" srcSet="../images/humandHands-p-500.png 500w, images/humandHands-p-800.png 800w, images/humandHands.png 1024w" />
                                            <div className="team-member-name-2">2. Korak</div>
                                            <div className="team-member-position">Započnite proces dobijanja svoje polise osiguranja</div>
                                            <p>Odaberite kategoriju osiguranja koju zahtjevate i unesite odgovarajuće podatke.</p>
                                        </div>
                                        <div id="w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500d4-408e5274" className="team-card"><img className="team-member-image" src="../images/getting-information-fast-from-computer-services-262910606.png" data-w-id="cfa202fe-2bd5-3e0b-25c9-7885e05500d5" loading="lazy" srcSet="../images/getting-information-fast-from-computer-services-262910606-p-500.png 500w, images/getting-information-fast-from-computer-services-262910606-p-800.png 800w, images/getting-information-fast-from-computer-services-262910606.png 1024w" />
                                            <div className="team-member-name-2">3. Korak</div>
                                            <div className="team-member-position">Povratne informacije</div>
                                            <p>U veoma kratkom roku dobijate ponude od brojnih osiguranja sa kojima surađujemo, sto nas čini pionirima u ovoj vrsti poslovanja.</p>
                                        </div>
                                        <div id="w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500de-408e5274" className="team-card"><img className="team-member-image" src="../images/making-a-decision-from-various-options-409172130.png" data-w-id="cfa202fe-2bd5-3e0b-25c9-7885e05500df" loading="lazy" srcSet="../images/making-a-decision-from-various-options-409172130-p-500.png 500w, images/making-a-decision-from-various-options-409172130-p-800.png 800w, images/making-a-decision-from-various-options-409172130.png 1024w" />
                                            <div className="team-member-name-2">4. Korak</div>
                                            <div className="team-member-position">Odabir polise</div>
                                            <p>Izaberite polisu koja najviše odgovara Vašim potrebama i zahtjevima.</p>
                                        </div>
                                        <div id="w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500e8-408e5274" className="team-card"><img className="team-member-image" src="../images/online-payment-205500337.png" data-w-id="cfa202fe-2bd5-3e0b-25c9-7885e05500e9" loading="lazy" srcSet="../images/online-payment-205500337-p-500.png 500w, images/online-payment-205500337-p-800.png 800w, images/online-payment-205500337.png 1024w" />
                                            <div className="team-member-name-2">5. Korak</div>
                                            <div className="team-member-position">Placanje</div>
                                            <p>Platite online karticom uz samo nekoliko klikova putem osiguranog gatewaya.</p>
                                        </div>
                                        <div id="w-node-cfa202fe-2bd5-3e0b-25c9-7885e05500f2-408e5274" className="team-card"><img className="team-member-image" src="../images/checklist-3679741_1280.jpg" data-w-id="cfa202fe-2bd5-3e0b-25c9-7885e05500f3" loading="lazy" srcSet="../images/checklist-3679741_1280-p-500.jpg 500w, images/checklist-3679741_1280-p-800.jpg 800w, images/checklist-3679741_1280-p-1080.jpg 1080w, images/checklist-3679741_1280.jpg 1280w" />
                                            <div className="team-member-name-2">Zavrsen proces</div>
                                            <div className="team-member-position">Za samo nekoliko minuta sve je gotovo</div>
                                            <p>Nakon plaćanja, važeću polisu dobijate na korisnički e-mail nalog.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="blog-heading">
                                <div className="label cc-light">o nama</div>
                                <h2 className="work-heading">Korišćenjem naše platforme otvarate vrata za razne pogodnosti</h2>
                                <div className="efi-ft-09">
                                    <div className="efi-ft-09-container">
                                        <div className="w-layout-grid efi-ft-09-grid">
                                            <div id="w-node-_41305b7f-56a4-1376-3bce-e6505bb59672-408e5274" className="efi-ft-09-grid-inner">
                                                <h2 className="efi-h2">Zašto da kupite osiguranje preko nas?</h2>
                                            </div>
                                            <div id="w-node-_41305b7f-56a4-1376-3bce-e6505bb59675-408e5274" className="efi-ft-09-grid-inner">
                                                <p className="efi-paragraph">Kada kupujete uslugu preko naše agencije, dobijate mnoge prednosti. Osim sigurnosti i mira uma, dobijate i personalizovane ponude, brz i jednostavan proces odabira, stručne savjete i podršku tokom cijelog trajanja osiguranja. Naša misija je da vam olakšamo put ka boljoj budućnosti i obezbjedimo najbolje rješenje za vaše potrebe.</p>
                                            </div>
                                        </div>
                                        <div className="efi-ft-09-accordion">
                                            <Accordion />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section cc-cta">
                        <div className="container">
                            <div className="cta-wrap">
                                <div>
                                    <div className="cta-text">
                                        <div className="heading-jumbo-small">Osiguraj sebe i svoju imovinu<br /></div>
                                        <div className="paragraph-bigger cc-bigger-light">Danas je dan da zaštitite svoju budućnost. Registracijom na našem sajtu, korak po korak, pravite sigurnije sutra. Ne čekajte više - započnite put prema mirnijem životu danas.<br /></div>
                                    </div>
                                    <a href="/registration" className="button cc-jumbo-button w-inline-block">
                                        <div>registruj se</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="partners"/>
                    <Propaganda/>
                    <Footer/>
                </div>
            );


};

export default LandingPage;
