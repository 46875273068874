import React, { useEffect, useState } from 'react';
import '../static/normalize.css';
import '../static/kaskorequestsview.css'; // New CSS file
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import config from '../config';
import Header from './HeaderAdmin';
import Footer from './Footer';
import LoadingSpinner from './LoadingSpinner';


function KaskoRequestsView() {
    const [groupedData, setGroupedData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [loading, setLoading] = useState(false);


    const [responseFormData, setResponseFormData] = useState({
        price: 0,
        policy: '',
        requestid: 0,
        pdfFile: null,
    });
    const [filterType, setFilterType] = useState('ALL'); // Default to show all cards

    const getAuthToken = () => {
        return localStorage.getItem('token');
    };

    const fetchData = async () => {
        try {
            const token = getAuthToken();
            setLoading(true);

            if (!token) {
                console.error('Auth error');
                setLoading(false);
                alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                return;
            }

            const response = await fetch(`${config.BASE_URL}api/policyreq/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                setLoading(false);
                alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                console.error('Failed to fetch data');
                return;
            }

            const result = await response.json();
            setLoading(false);
            const grouped = result.reduce((acc, card) => {
                const dateKey = card.date.split('T')[0];
                acc[dateKey] = acc[dateKey] || [];
                acc[dateKey].push(card);
                return acc;
            }, {});

            setGroupedData(grouped);
        } catch (error) {
            setLoading(false);
            alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCreateResponse = async () => {
        const { price, policy, requestid, pdfFile } = responseFormData;

        // Validation checks
        if (!price || !policy || !requestid || !pdfFile) {
            alert('Sva polja moraju biti popunjena!');
            return;
        }

        try {
            const token = getAuthToken();
            setLoading(true);
            if (!token) {
                console.error('JWT not found in localStorage');
                return;
            }

            const formData = new FormData();
            formData.append('price', responseFormData.price);
            formData.append('policy', responseFormData.policy);
            formData.append('requestid', responseFormData.requestid);
            formData.append('pdfFile', responseFormData.pdfFile);

            const response = await fetch(`${config.BASE_URL}api/policyres/create`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                setLoading(false);
                console.error(`Error creating response: ${response.statusText}`);
                return;
            }

            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                setLoading(false);
                const result1 = await response.json();
            } else {
                const result1 = await response.text();
                setLoading(false);

                if (result1 === 'OK') {
                    alert('Polisa je uspješno kreirana i poslana kupcu!');
                }
                if (result1 === 'EMPTY') {
                    alert('Došlo je do problema!');
                }
                if (result1 === 'ERROR') {
                    alert('Došlo je do većeg problema!');
                }
                handleCloseModal();
            }
        } catch (error) {
            setLoading(false);
            alert('Došlo je do greške u sistemu');
            console.error('Error creating response:', error);
            handleCloseModal();
        }
    };

    const handlePdfFileChange = (event) => {
        const file = event.target.files[0];
        setResponseFormData((prevFormData) => ({
            ...prevFormData,
            pdfFile: file,
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCardClick = (card) => {
        setSelectedCard(card);
        setShowModal(true);

        setResponseFormData((prevFormData) => ({
            ...prevFormData,
            requestid: card.id,
        }));
    };

    const renderAdditionalDetails = (card) => {
        switch (card.type) {
            case 'CAR':
                return (
                    <>
                        <p>Stanje: {card.newornot ? 'Novo' : 'Polovno'}</p>
                        <p>Godina: {card.year}</p>
                        <p>Marka: {card.brand}</p>
                        <p>Kilometraža: {card.mileage}</p>
                        <p>Kilovati: {card.kilowatts}</p>
                        <p>Kubikaza: {card.capacity}</p>
                        <p>Dodatni komentar: {card.description}</p>
                    </>
                );
            case 'TRUCK':
                return (
                    <>
                        <p>Stanje: {card.newornot ? 'Novo' : 'Polovno'}</p>
                        <p>Godina: {card.year}</p>
                        <p>Marka: {card.brand}</p>
                        <p>Kilometraža: {card.mileage}</p>
                        <p>Kilovati: {card.kilowatts}</p>
                        <p>Dodatni komentar: {card.description}</p>
                        <p>Kapacitet (Za teretna vozila): {card.capacity}</p>
                    </>
                );
            case 'MOTORCYCLE':
                return (
                    <>
                        <p>Stanje: {card.newornot ? 'Novo' : 'Polovno'}</p>
                        <p>Godina: {card.year}</p>
                        <p>Marka: {card.brand}</p>
                        <p>Kilometraža: {card.mileage}</p>
                        <p>Kilovati: {card.kilowatts}</p>
                        <p>Dodatni komentar: {card.description}</p>
                        <p>Zapremina motora (Za motocikle): {card.capacity}</p>
                    </>
                );
            default:
                return null;
        }
    };

    // Function to filter cards based on type
    const filterCards = (data) => {
        if (filterType === 'ALL') {
            return data;
        } else {
            return data.filter(card => card.type === filterType);
        }
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    return (
        <div>
            <Header />
            {/* Filter section */}
            <div className="filter-section">
                <label htmlFor="filter">Filtrirajte po vrsti motornih vozila:</label>
                <select id="filter" value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                    <option value="ALL">Svi</option>
                    <option value="CAR">Automobil</option>
                    <option value="MOTORCYCLE">Motocikl</option>
                    <option value="TRUCK">Teretno vozilo</option>
                </select>
            </div>

            {/* Render cards grouped by date */}
            {Object.keys(groupedData)
                .sort((a, b) => new Date(b) - new Date(a))
                .map((date) => (
                    <div className="kasko-div-block" key={date}>
                        <h1 className="heading-4">{formatDate(date)}</h1>
                        <div className="kasko-lynx-content">
                            {filterCards(groupedData[date]).slice().reverse().map((card) => (
                                <div className="kasko-lynx-features-bg" key={card.id}>
                                    <div className="kasko-lynx-block-features">
                                        {/* Display all card data */}
                                        <p>Ime vlasnika: {card.firstname}</p>
                                        <p>Prezime vlasnika: {card.lastname}</p>
                                        <p>Tip: {card.type === 'CAR' ? 'AUTOMOBIL' : (card.type === 'TRUCK' ? 'TERETNO VOZILO' : 'MOTOCIKL')}</p>
                                        {/* Render additional details based on type */}
                                        {renderAdditionalDetails(card)}
                                        {/* Button to create a response */}
                                        <button className="kasko-button" onClick={() => handleCardClick(card)}>
                                            Kreiraj odgovor na zahtjev za polisu
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

            {/* Modal or form for creating response */}
            {showModal && selectedCard && (
                <div className="kasko-modal-overlay">
                    <div className="kasko-modal-content">
                        {/* Display selected card data in the modal */}
                        <p>Datum: {formatDate(selectedCard.date)}</p>
                        {/* ... Include other fields here ... */}
                        <p>Unesite cijenu</p>
                        <input
                            type="number"
                            placeholder="Cijena"
                            value={responseFormData.price}
                            onChange={(e) =>
                                setResponseFormData((prev) => ({ ...prev, price: e.target.value }))
                            }
                        />
                        <p>Unesite broj polise</p>
                        <input
                            type="text"
                            placeholder="Broj polise"
                            value={responseFormData.policy}
                            onChange={(e) =>
                                setResponseFormData((prev) => ({ ...prev, policy: e.target.value }))
                            }
                        />
                        <p>Priložite fajl sa polisom</p>
                        <input type="file" onChange={handlePdfFileChange} />
                        <button onClick={() => handleCreateResponse()}>Pošalji polisu</button>
                        <button onClick={() => handleCloseModal()}>Zatvori</button>
                    </div>
                    <LoadingSpinner loading={loading} />
                </div>
            )}
            <Footer />
        </div>
    );
}

export default KaskoRequestsView;
