import React, { useEffect, useState } from 'react';
import '../static/MainPage.css'; // Correct relative path
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import '../static/kaskoresponseview.css';
import { Document, Page } from 'react-pdf';
import axios from 'axios';
import config from '../config';
import Header from './HeaderUser';
import Footer from './FooterUser';
import LoadingSpinner from './LoadingSpinner';

function PropertyResponseView() {
    const [groupedResponses, setGroupedResponses] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [selectedResponseForPayment, setSelectedResponseForPayment] = useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [responsesForSelectedRequest, setResponsesForSelectedRequest] = useState([]);
    const [noResponsesMessage, setNoResponsesMessage] = useState(false);
    const [showPaymentButton, setShowPaymentButton] = useState(false);
    const [paymentSlip, setPaymentSlip] = useState(null);
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const getAuthToken = () => {
        return localStorage.getItem('token'); // Retrieve JWT from localStorage
    };

    const fetchResponses = async () => {
        try {
            const token = getAuthToken();
            if (!token) {
                console.error('JWT not found in localStorage');
                return;
            }
            const response = await fetch(`${config.BASE_URL}api/policyres/getproperty/all`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            console.log(result);
            setGroupedResponses(result);
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    };

    const fetchPdfData = async (responseId) => {
        try {
            console.log(responseId);
            setSelectedResponseForPayment(responseId);
            const token = getAuthToken();
            if (!token) {
                console.error('JWT not found in localStorage');
                return;
            }
            const response = await axios.get(`${config.BASE_URL}api/policyres/getkasko/getPdf?id=${responseId}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`, // Add the token to the headers
                },
            });
            const file = window.URL.createObjectURL(response.data);
            setPdfData(file);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    const fetchResponsesByRequestId = async (requestId) => {
        try {
            const token = getAuthToken();
            setLoading(true);
            if (!token) {
                setLoading(false);
                alert("Došlo je do greške u sistemu, molimo pokušajte ponovo!");
                console.error('JWT not found in localStorage');
                return;
            }
            const response = await axios.get(`${config.BASE_URL}api/policyres/getproperty?requestid=${requestId}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Add the token to the headers
                },
            });
            setLoading(false);
            setResponsesForSelectedRequest(response.data);
            setNoResponsesMessage(response.data.length === 0);
        } catch (error) {
            setLoading(false);
            alert("Došlo je do greške u sistemu, molimo pokušajte ponovo");
            console.error('Error fetching responses by request ID:', error);
        }
    };

    useEffect(() => {
        if (selectedResponse) {
            fetchResponsesByRequestId(selectedResponse.id);
        }
    }, [selectedResponse]);

    useEffect(() => {
        fetchResponses();
        if (selectedResponse) {
            fetchPdfData(selectedResponse.id);
        }
    }, []);

    const handleResponseChange = (e) => {
        setSelectedResponse(JSON.parse(e.target.value));
        setPdfData(null);
        setPaymentSlip(null);
        setShowPaymentButton(false);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleBuyButtonClick = (response) => {
        fetchPdfData(response.id);
        setShowPaymentButton(true);
    };

    const handleGeneratePaymentSlip = async () => {
        try {
            const token = getAuthToken();
            setLoading(true);
            if (!token) {
                setLoading(false);
                alert("Došlo je do greške u sistemu, molimo pokušajte ponovo!");
                return console.error('JWT not found in localStorage');
            }

            const response = await axios.post(`${config.BASE_URL}api/payment/generate`,
                {
                    responseId: selectedResponseForPayment,
                    phoneNumber: phoneNumber, // Include the phone number in the request
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob'
                }
            );

            const file = window.URL.createObjectURL(response.data);
            setPaymentSlip(file);
            setLoading(false);
            alert('Uplatnica generisana uspešno!');
        } catch (error) {
            setLoading(false);
            alert("Došlo je do greške u sistemu, molimo pokušajte ponovo!");
            console.error('Error generating payment slip:', error);
        }
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="dropdown-container">
                    <select className="dropdown-menu" onChange={handleResponseChange}>
                        <option className="dropdown-menu-option" value={null}>Izaberite nekretninu za koju želite da vidite ponude</option>
                        {groupedResponses.slice().reverse().map((response) => (
                            <option key={response.id} value={JSON.stringify(response)}>
                                Datum: {formatDate(response.date)}, ime i prezime vlasnika: {response.ownerfirstname} {response.ownerlastname}, Godina izgradnje: {response.year}, Kvadratura objekta: {response.size}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedResponse && (
                    <div className="response-container">
                        <h2>Gledate ponude za zahtev za imovinsko osiguranje za nekretninu {selectedResponse.ownerfirstname} {selectedResponse.ownerlastname}, {selectedResponse.year} godište, veličine {selectedResponse.size} </h2>
                        <div className="response-details">
                            <p><strong>Datum ponude:</strong> {formatDate(selectedResponse.date)}</p>
                            <p><strong>Godina izgradnje:</strong> {selectedResponse.year}</p>
                            <p><strong>Kvadratura objekta:</strong> {selectedResponse.size}</p>
                        </div>
                        {noResponsesMessage && <p className="no-response-message">Trenutno nemate odgovora na zahtevanu polisu, molimo pričekajte, ukoliko vam je hitno pozovite broj telefona 066xxxxxx.</p>}
                        <div className="offers-container">
                            {responsesForSelectedRequest.map((response) => (
                                <div key={response.id} className="offer-box">
                                    <h3><strong>Cena:</strong> {response.amount} Konvertibilnih maraka</h3>
                                    <p><strong>Osiguravajuća kuća:</strong> {response.insurancehouse}</p>
                                    <button className="buy-button" onClick={() => handleBuyButtonClick(response)}>Pogledajte detalje polise (PDF fajl) i kupite polisu</button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {pdfData && (
                    <div className="pdf-container">
                        <iframe src={pdfData} width="600" height="600" style={{margin:"auto"}}/>
                        {showPaymentButton && (
                            <div className="phone-number-container">
                                <p className="phone-number-text">Ukoliko želite kupiti izabranu polisu, unesite Vaš broj telefona kako bismo vas mogli kontaktirati i izvršiti proces verifkacije nekretnine.</p>
                                <input
                                    id="phone-number"
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    placeholder="06xxxxxxxx"
                                    required
                                    className="phone-number-input"
                                />
                                <button className="payment-button" onClick={handleGeneratePaymentSlip}>Generišite uplatnicu</button>
                            </div>
                        )}
                    </div>
                )}

                {paymentSlip && (
                    <div className="pdf-container">
                        <iframe src={paymentSlip} width="600" height="600" />
                    </div>
                )}
            </div>
            <LoadingSpinner loading={loading} />
            <Footer />
        </div>
    );
}

export default PropertyResponseView;
